.ck-editor__editable {
  min-height: 300px;
  max-height: 500px;
  overflow-y: auto;
}

.item-container {
  display: flex;
  align-items: center;
}

.delete-button {
  margin-left: 10px; 
  height: fit-content; 
  width: auto; 
  white-space: nowrap; 
}


.flex{
    display: flex;
}

.dash-info-wrap{
    &>.flex{
        margin-top: 20px;
    }
    .dash-info-item{
        .dash-link-item {
            display: block;
            border: 2px solid #f3f3f3;
            border-radius: 15px;
            text-align: center;
        }
        
        a{
            display: block;
            border: 2px solid #f3f3f3;
            border-radius: 15px;
        }
        width: 25%;
        margin: 0 15px;
        text-align: center;
        h4{
            margin-bottom: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 2px solid #f3f3f3;
            font-weight: bold;
            font-size: 18px;
            background: #f0f0f0;
        }
        strong{
            font-size: 20px;
            display: block;
            font-weight: bold;
            line-height: 1.5;
            padding-bottom: 15px;
        }
    }
}

.dash-ti{
    position: relative;
    font-size: 18px;
    padding-left: 10px;
    margin-bottom: 15px;
    &:after{
        position: absolute;
        left: 0;
        top: 7px;
        display: block;
        content:'';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #313a46;
    }
}

.dash-wrap-cont{
    padding: 20px;
    border-radius: 15px;
    background: #fff;
    border: 1px solid #dedeed;
    margin-bottom: 20px;
    font-size: 15px;
}

.dash-notice-li{
    .category{
        display: block;
        width: 120px;
    }
    .data{
        text-align: right;
        display: block;
        width: 100px;
        font-size: 13px;
    }
    .subject{
        width: calc(100% - 200px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; 
    }
}

.progress-ui{
    display: flex;
    align-items: center;
    justify-content: space-between;
    +.progress-ui{
        margin-top: .5rem;
    }
    label{
        line-height: 1;
        display: inline-block;
        position: relative;
        padding-left: 1rem;
        font-size: 1rem;
        &::after{
            content:"";
            display: inline-block;
            position: absolute;
            width: .5rem;
            height: .5rem;
            border-radius: 50%;
            left: 0;
            top: .2rem;
        }
    }
    .progress-group{
        width: calc(100% - 12rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #edf1f4;
        border-radius: .4rem;
        padding: .3rem .6rem;
        progress{
            display: block;
            height: .5rem;
            border-radius: .25rem;
            overflow: hidden;
        }
        progress[value]{
            display: block;
            width: calc(100% - 8rem);
            border-radius: 0.8rem;
            height: .5rem;
            border-radius: .25rem;
            &::-webkit-progress-bar{
                background: #fff;
                border-radius: .25rem;
            }
            &::-webkit-progress-value {
                border-radius: .25rem;
            }
        }

        .count{
            text-align: right;
            color: #000;
            font-size: 1rem;
        }
    }
    &.theme-blue{
        label{
            &::after{
                background-color: #4a639b;
            }
        }
        progress[value]{
            &::-webkit-progress-value {
                background: #4a639b;
            }
        }
    }
    &.theme-sky{
        label{
            &::after{
                background-color: #6dc9eb;
            }
        }
        progress[value]{
            &::-webkit-progress-value {
                background: #6dc9eb;
            }
        }
    }
    &.theme-darksky{
        label{
            &::after{
                background-color: #5db2e0;
            }
        }
        progress[value]{
            &::-webkit-progress-value {
                background: #5db2e0;
            }
        }
    }
}
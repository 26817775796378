
.flex{
    display: flex;
}

.dash-info-wrap{
    &>.flex{
        margin-top: 20px;
    }
    .dash-info-item{
        a{
            display: block;
            border: 2px solid #f3f3f3;
            border-radius: 15px;
        }
        width: 25%;
        margin: 0 15px;
        text-align: center;
        h4{
            margin-bottom: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 2px solid #f3f3f3;
            font-weight: bold;
            font-size: 18px;
            background: #f0f0f0;
        }
        strong{
            font-size: 20px;
            display: block;
            font-weight: bold;
            line-height: 1.5;
            padding-bottom: 15px;
        }
    }
}

.dash-ti{
    position: relative;
    font-size: 18px;
    padding-left: 10px;
    margin-bottom: 15px;
    &:after{
        position: absolute;
        left: 0;
        top: 7px;
        display: block;
        content:'';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #313a46;
    }
}

.dash-wrap-cont{
    padding: 20px;
    border-radius: 15px;
    background: #fff;
    border: 1px solid #dedeed;
    margin-bottom: 20px;
    font-size: 15px;
}

.dash-notice-li{
    .category{
        display: block;
        width: 200px !important;
    }
    .data{
        text-align: right;
        display: block;
        width: 100px;
        font-size: 13px;
    }
    .subject{
        width: calc(100% - 300px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; 
    }
}